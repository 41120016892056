import React from 'react';
import './Footer.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import resumePdf from './resources/Resume, Hasan Kamal.pdf';

class Footer extends React.Component{
	render(){
		const thanks = (
			<Row>
				<Col>
					<p>
						<small>
							Thanks for stopping by!
						</small>
					</p>
				</Col>
			</Row>
		);

		const version = (
			<Row>
				<Col>
					<p>
						<small>
							v29
						</small>
					</p>
				</Col>
			</Row>
		);

		const links = {
			email: 'mailto:hasankam97@gmail.com',
			github: 'https://github.com/hasan-kamal',
			linkedIn: 'https://www.linkedin.com/in/hasan-kamal/',
		};

		const contactLinks = (
			<Row>
				<Col xs={3} lg={3}></Col>
				
				<Col xs={2} lg={2} className="border-top footer-link">
					<small>
						<a href={links.email} target="_blank">Email</a>
					</small>
				</Col>

				<Col xs={2} lg={2} className="border-top footer-link">
					<small>
						<a href={links.github} target="_blank">GitHub</a>
					</small>
				</Col>

				<Col xs={2} lg={2} className="border-top footer-link">
					<small>
						<a href={links.linkedIn} target="_blank">LinkedIn</a>
					</small>
				</Col>

				<Col xs={3} lg={3}></Col>
			</Row>
		);

		return (
			<Container className="text-center">
			{thanks}
			{version}
			{contactLinks}
			</Container>
		);
	}
}

export default Footer;