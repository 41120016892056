import React from 'react';
import './Experience.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

import gsIcon from './img/internships/goldman_sachs.png';
import googleIcon from './img/internships/google.png';

const experiences = [
	{
		image: googleIcon,
		imageLink: null,
		anchorName: 'google-full-time',
		title: 'Software Engineer, Google',
		location: 'San Francisco Bay Area, California, United States',
		date: 'July 2021 - Present',
		links: [],
		description: 
		<span>
			Working on Cloud Spanner - Google's horizontally scalable distributed relational database. Developing software to improve reliability and observability of the system.
		</span>
	},
	{
		image: googleIcon,
		imageLink: null,
		anchorName: 'google-internship',
		title: 'Software Engineer Intern, Google',
		location: 'Mountain View, California, United States',
		date: 'May 2020 - August 2020',
		links: [],
		description: 
		<span>
			Worked on 4G/LTE Evolved Packet Core (EPC) distributed system in Google Fi team:
			<ul>
				<li>Increased speed of mastership change by 7% by implementing a new remote procedure call (RPC) service in C++.</li>
				<li>Improved product consistency and code health by adding a new cleaner master election mechanism in C++.</li>
				<li>Added metrics in C++ to monitor time taken by various phases of mastership change process.</li>
			</ul>
		</span>
	},
	{
		image: gsIcon,
		imageLink: null,
		anchorName: 'gs-internship',
		title: 'Software Engineer Intern, Goldman Sachs',
		location: 'Bengaluru, India',
		date: 'May 2018 - June 2018',
		links: [],
		description: 
		<span>
			Revamped an internal legacy Java application into a modern web application:
			<ul>
				<li>Designed UI of web application tool in React allowing faster client account creation process.</li>
				<li>Applied design pattern principles to allow reuse of a component with different validation requirements.</li>
				<li>Designed components to enable a quicker and easier workflow for employees.</li>
			</ul>
		</span>
	}
];

class ResponsiveCard extends React.Component{
	render(){
		const image = (
			<Col xs={12} md={2} className="project-image-container">
				<table>
					<tbody>
						<tr>
							<td><a href={this.props.imageLink} target="_blank"><Image src={this.props.image} className="project-image" /></a></td>
						</tr>
					</tbody>
				</table>
			</Col>
		);

		const card = (
			<Col>
				<CardGroup>
					<Card border="light" bg="light">
					  <Card.Body>
					    <Card.Title>{this.props.title}</Card.Title>
					    <Card.Subtitle className="mb-2 text-muted">{this.props.location}, <i>{this.props.date}</i></Card.Subtitle>
					    <Card.Text>
					      {this.props.children}
					    </Card.Text>
					    {
					    	this.props.links.map((link, index) => (
					    		<Card.Link key={index} href={link.address} target="_blank">{link.displayText}</Card.Link>
					    	))
					    }
					  </Card.Body>
					</Card>
				</CardGroup>
			</Col>
		);

		return (
			<Container className="margin-top-internship border-left">
				<a name={this.props.anchorName}></a>
				<Row>
					{image}
					{card}
				</Row>
			</Container>
		);
	}
}

class Experiences extends React.Component{
	render(){
		return (
			<Container className="container-internships-padding">
				<h4>Work Experience</h4>
				{
					experiences.map((project, index) => (
						<ResponsiveCard
							key={index}
							id={index}
							image={project.image}
							imageLink={project.imageLink}
							title={project.title}
							location={project.location}
							date={project.date}
							links={project.links}
							anchorName={project.anchorName}
						>
							{project.description}
						</ResponsiveCard>
					))
				}

			</Container>
		);
	}
}

export default Experiences;